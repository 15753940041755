import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Avatar, AvatarSize, Icon, Colors, Badge } from '@class101/ui';
import { IMAGE_URL } from './Avatar';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "avatar"
    }}>{`Avatar`}</h1>
    <p>{`Avatars can be used to represent people profile.
It can be used with a badge, and can display a number of notifications or a specific icon.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Avatar} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <p>{`src가 없거나 이미지 로딩에 실패했을 때 text가 보이게 됩니다.`}</p>
    <Playground __position={1} __code={'<Avatar src={IMAGE_URL} />\n<Avatar src=\"invaild_url\" text=\"가나다라\" />\n<Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Avatar,
      AvatarSize,
      Icon,
      Colors,
      Badge,
      IMAGE_URL,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar src={IMAGE_URL} mdxType="Avatar" />
  <Avatar src="invaild_url" text="가나다라" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} mdxType="Avatar" />
    </Playground>
    <h2 {...{
      "id": "with-icon"
    }}>{`With Icon`}</h2>
    <p>{`Avatar의 icon에 들어가는 컴포넌트는 자동적으로 size={number}를 주입받습니다.`}</p>
    <p><inlineCode parentName="p">{`<Badge />`}</inlineCode>{`나 `}<inlineCode parentName="p">{`<Icon />`}</inlineCode>{` 컴포넌트를 넣는 것을 추천합니다.`}</p>
    <h3 {...{
      "id": "badge"
    }}>{`Badge`}</h3>
    <Playground __position={2} __code={'() => {\n  const badgeWithIcon = (\n    <Badge backgroundColor={Colors.orange600} pill>\n      <Icon.Crown fillColor={Colors.white} />\n    </Badge>\n  )\n  return (\n    <Avatar size={AvatarSize.LARGE} src={IMAGE_URL} icon={badgeWithIcon} />\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Avatar,
      AvatarSize,
      Icon,
      Colors,
      Badge,
      IMAGE_URL,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const badgeWithIcon = <Badge backgroundColor={Colors.orange600} pill mdxType="Badge">
        <Icon.Crown fillColor={Colors.white} />
      </Badge>;
        return <Avatar size={AvatarSize.LARGE} src={IMAGE_URL} icon={badgeWithIcon} mdxType="Avatar" />;
      }}
    </Playground>
    <h3 {...{
      "id": "icon"
    }}>{`Icon`}</h3>
    <Playground __position={3} __code={'<Avatar\n  size={AvatarSize.SMALL}\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n/>\n<Avatar\n  size={64}\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"bottom-left\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"bottom\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Avatar,
      AvatarSize,
      Icon,
      Colors,
      Badge,
      IMAGE_URL,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar size={AvatarSize.SMALL} src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} mdxType="Avatar" />
  <Avatar size={64} src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="bottom-left" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="bottom" mdxType="Avatar" />
    </Playground>
    <h2 {...{
      "id": "icon-position"
    }}>{`Icon Position`}</h2>
    <p>{`iconPosition으로 아이콘이 위치할 곳을 정할 수 있습니다.`}</p>
    <p>{`기본은 `}<inlineCode parentName="p">{`bottom-right`}</inlineCode>{`입니다.`}</p>
    <Playground __position={4} __code={'<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"bottom-left\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"bottom\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"bottom-right\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"left\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"right\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"top-left\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"top\"\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  iconPosition=\"top-right\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Avatar,
      AvatarSize,
      Icon,
      Colors,
      Badge,
      IMAGE_URL,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="bottom-left" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="bottom" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="bottom-right" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="left" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="right" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="top-left" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="top" mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} iconPosition="top-right" mdxType="Avatar" />
    </Playground>
    <h2 {...{
      "id": "event"
    }}>{`Event`}</h2>
    <p><inlineCode parentName="p">{`onClick`}</inlineCode>{`과 `}<inlineCode parentName="p">{`onIconClick`}</inlineCode>{`을 둘 다 설정한 경우 `}<inlineCode parentName="p">{`onIconClick`}</inlineCode>{`이 실행된 후 `}<inlineCode parentName="p">{`onClick`}</inlineCode>{`이 실행됩니다.`}</p>
    <p>{`원하지 않는 경우 `}<inlineCode parentName="p">{`e.stopPropagation()`}</inlineCode>{`를 실행해서 전파를 막아야 합니다.`}</p>
    <Playground __position={5} __code={'<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  onClick={() => alert(\'click1\')}\n  onIconClick={() => alert(\'click2\')}\n/>\n<Avatar\n  src={IMAGE_URL}\n  icon={<Icon.Heart fillColor={Colors.orange600} />}\n  onClick={() => alert(\'click1\')}\n  onIconClick={e => {\n    alert(\'click2\')\n    e.stopPropagation()\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Avatar,
      AvatarSize,
      Icon,
      Colors,
      Badge,
      IMAGE_URL,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} onClick={() => alert('click1')} onIconClick={() => alert('click2')} mdxType="Avatar" />
  <Avatar src={IMAGE_URL} icon={<Icon.Heart fillColor={Colors.orange600} />} onClick={() => alert('click1')} onIconClick={e => {
        alert('click2');
        e.stopPropagation();
      }} mdxType="Avatar" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      